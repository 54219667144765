import React from 'react'
import Layout from "../../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

function Webdesign() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Webdesign - Bart Sallé, freelance webdeveloper";
  const description = "Zoek je iemand om jouw ontwerp om te laten zetten naar een website of webshop? Of wil je deze volledig door mij laten ontwerpen? Allebei mogelijk!";
  const url = "https://www.bartsalle.nl/diensten/webdesign/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="overzichtdiensten" />
      </Helmet>
        <div className="container">
          <h1>Webdesign</h1>
          <StaticImage src="../../../images/portfolio/webdesign.png" alt="webdesign voorbeeld" layout="constrained" width={700} className="float-right webdesign" placeholder="none" />
          <p>Op zoek naar een betrouwbare developer voor je website of webshop? Ik bouw hem graag voor je!</p>
          <p>Ik werk veel voor ondernemers, zzp’ers en ontwerpbureau’s, zowel in Nederland als over de grens. Zoek je dus iemand om jouw ontwerp om te laten zetten naar een website of webshop? Of wil je deze volledig door mij laten ontwerpen? Allebei mogelijk!</p>
          <p>De Wordpress websites en WooCommerce webshops die ik bouw zijn:</p>
          <p>
            <ul>
              <li>Functioneel</li>
              <li>Snel</li>
              <li>Geoptimaliseerd voor Google</li>
              <li>Veilig</li>
              <li>Schoon (geen overmatig gebruik van plugins)</li>
              <li>Betaalbaar (!)</li>
            </ul>
            <br />
          </p>
          <h2>Websites (achtergrond)</h2>
          <p>Als grote liefhebber van Open Source Content Management Systemen (CMS) heb ik met alle grote spelers ruime ervaring. Dit houdt in dat ik websites maar ook templates en modules/extensies heb gebouwd voor Wordpress, Drupal, TYPO3 (vanwege mijn verleden bij de overheid), Joomla (in het verleden veel mee gewerkt) en ProcessWire.</p>
          <p>Aangezien het gros van mijn klanten gebruik maakt van WordPress heb ik destijds besloten om mij hier met name op te gaan focussen.</p>
          <h2>Wordpress webdesign</h2>
          <p>Inmiddels heb ik al honderden Wordpress websites en webwinkels ontwikkeld. Daarnaast houd ik alle laatste ontwikkelingen op Wordpress gebied bij. Als Wordpress expert ken ik het systeem dus van binnen en buiten en kan ik je dus altijd verder helpen.</p>
          <p>Door mijn jarenlange ervaring weet ik hoe je het beste een Wordpress website moet bouwen en heb ik ook veel voorbeelden gezien van hoe het absoluut niet moet. Denk je dat jouw Wordpress website  verbeterd kan worden? Aarzel niet om <Link to="/contact/">contact met mij op te nemen!</Link></p>
          <h2>Webwinkels (achtergrond)</h2>
          <p>Ik ben in een ver verleden begonnen met het bouwen van webwinkels op basis van osCommerce (bestaat nog steeds!). Daarna diverse webwinkels gebouwd op basis van Joomla/Virtuemart want daarmee had je toen een belangrijk voordeel; templating!</p>
          <p>De volgende stap was Prestashop, dit was al meer geavanceerde e-commerce software met zaken zoals multishop en meertaligheid out of the box. Aangezien ik al vanaf het prille begin betrokken was bij Prestashop heb ik de allereerste Prestashop webwinkels in Nederland gebouwd. Daarnaast heb ik ook diverse technische Prestashop boeken gereviewed voor Packt Publishing en zul je mijn naam dus ook in een aantal Prestashop boeken terug vinden. Ik ben ook nog steeds fan van Prestashop.</p>
          <h2>WooCommerce webshops</h2>
          <p>Uiteindelijk had ik besloten om mij met name met WooCommerce bezig te gaan houden en al jaren bouw ik met name webshops op basis van Wordpress/WooCommerce. Als het gaat om veelzijdigheid en mogelijkheden is deze software ongeëvenaard en in mijn ogen de Swiss Army Knive onder de webshop software. Ook schrijf ik WooCommerce koppelingen.</p>
          <p>Ben je op zoek naar een WooCommerce specialist of heb je WooCommerce ondersteuning nodig? <Link to="/contact/">Ik help je graag!</Link></p>
          <h2>Liever geen Wordpress?</h2>
          <p>Ondanks dat ik weinig redenen kan bedenken om niet voor Wordpress te kiezen zijn er uiteraard alternatieven.</p>
          <p><strong>Statische websites zijn weer helemaal hot!</strong></p>
          <p>Met name vanwege de snelheid maar ook uit veiligheidsoverwegingen stappen steeds meer bedrijven over naar statische websites. Puur HTML, CSS en Javascript en geen server-side programmeertaal meer als PHP.</p>
          <p>In het prille begin bouwde ik volop statische websites en nu ben ik er dus weer zeer regelmatig mee bezig. Alleen nu op basis van moderne technieken.</p>
          <p>Zo bouw ik supersnelle websites op basis van <a href="https://reactjs.org/" target="_blank" rel="nofollow">React</a> (een Javascript bibliotheek waar websites als Facebook, Netflix, Airbnb en Instagram gebruik van maken) maar ook op basis van frameworks als <a href="https://astro.build/" target="_blank" rel="nofollow">Astro</a>.<br /><StaticImage src="../../../images/logo-astro.png" alt="webdesign voorbeeld" layout="constrained" width={200} className="image-margin-top-bottom" placeholder="none" />
          </p>
          <p>Ook deze sites zijn volledig geoptimaliseerd op SEO gebied. Gecombineerd met de snelle laadtijden zorgt dit ervoor dat jouw site aanzienlijk hoger scoort in Google dan de meeste andere sites.</p>
          <p><strong>Headless CMS</strong></p>
          <p>Koppel daar je favoriete CMS aan (zoals Wordpress) die we vervolgens headless inzetten en je hebt een killer combination. <Link to="/contact/">Ik vertel je hier graag meer over!</Link></p>
        </div>
    </Layout>
  )
}

export default Webdesign